import React from "react"
import * as textTileStyles from "./textTile.module.scss"

function TextTile(props) {
    const {content} = props
    return(
        <div className={`${textTileStyles.textTileContent}`}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
    )
}

export default TextTile