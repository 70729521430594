import React from "react"
import BackgroundImage from "gatsby-background-image"
import * as infoImageTileStyles from "./infoImageTile.module.scss"

function InfoImageTile(props) {
  const { image, heading, days, hours, minutes, seconds } = props
  return (
    <div className={infoImageTileStyles.imageBox}>
      <BackgroundImage
        className={`${infoImageTileStyles.image}`}
        fluid={image}>
        <div className={infoImageTileStyles.content}>
          <div className={infoImageTileStyles.heading}>
            <h1>{heading}</h1>
          </div>
          {(seconds + minutes + hours + days) > 0 &&
            <div className={`${infoImageTileStyles.countdownContainer} columns is-mobile`}>
              <div className={`${infoImageTileStyles.countdown} column is-3`}>
                <div className={infoImageTileStyles.number}>{days}</div>
                <div>Tage</div>
              </div>
              <div className={`${infoImageTileStyles.countdown} column is-3`}>
                <div className={infoImageTileStyles.number}>{hours}</div>
                <div>Stunden</div>
              </div>
              <div className={`${infoImageTileStyles.countdown} column is-3`}>
                <div className={infoImageTileStyles.number}>{minutes}</div>
                <div>Minuten</div>
              </div>
              <div className={`${infoImageTileStyles.countdown} column is-3`}>
                <   div className={infoImageTileStyles.number}>{seconds}</div>
                <div>Sekunden</div>
              </div>
            </div>
          }
        </div>
      </BackgroundImage>
    </div>
  )
}

export default InfoImageTile
