import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import InfoImageTile from "../../components/infoImageTile"
import HeadingTile from "../../components/headingTile"
import TextTile from "../../components/textTile"

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    const [days, hours, minutes, seconds] = this.calculateDifferenceBetweenDates()
    this.state = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.setCountDown(),
      1000,
    )
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  setCountDown() {
    const [days, hours, minutes, seconds] = this.calculateDifferenceBetweenDates()
    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    })
  }

  calculateDifferenceBetweenDates() {
    const jugDateString = this.props.data.allMarkdownRemark.edges[0].node.frontmatter.jsdDate
    const jugDate = new Date(jugDateString)
    const now = new Date()
    if (jugDate <= now) {
      return [0, 0, 0, 0]
    }
    let seconds = Math.floor((jugDate - (now)) / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)

    hours = hours - (days * 24)
    minutes = minutes - (days * 24 * 60) - (hours * 60)
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60)

    return [days, hours, minutes, seconds]
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const node = data.allMarkdownRemark.edges[0].node
    const { days, hours, minutes, seconds } = this.state
    const hashtag = node.frontmatter.hashtag

    let infosImg
    data.allFile.edges.forEach((edge) => {
      if (!edge.node.childImageSharp) {
        return
      }
      const imageName = edge.node.childImageSharp.fluid.originalName.split(".")[0]
      if (imageName === "infos") {
        infosImg = edge.node.childImageSharp.fluid
      }
    })

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title}
             description={node.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer>
            <div className="columns">
              <div className="column is-12">
                <div>
                  <InfoImageTile image={infosImg} heading={hashtag} days={days} hours={hours} minutes={minutes}
                                 seconds={seconds} />
                </div>
              </div>
            </div>
            <div className={`columns`}>
              <div className={`column is-6`}>
                <HeadingTile heading={node.frontmatter.title}
                             subHeading={node.frontmatter.subtitle}
                />
              </div>
              <div className={`column is-6`}>
                <TextTile
                  content={node.html}
                />
              </div>
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/assets/"}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    } 
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/day/(infos.md)/"}}) {
      edges {
        node {
          fields {
            slug
          }
          html
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            jsdDate
            hashtag
            seoTitle
            seoDescription
          }
        }
      }
    }
    
  }
`
