import React from "react"
import * as headingTileStyles from "./headingTile.module.scss"

function HeadingTile(props) {
    const {heading, subHeading} = props
    return(
        <div className={`${headingTileStyles.headingTileContent}`}>
            <h1 className="title is-1">{heading}</h1>
            {subHeading && <h2 className="subtitle">{subHeading}</h2>}
        </div>
    )
}

export default HeadingTile
